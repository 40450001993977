import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav className="bg-black text-white py-4">
      <ul className="flex justify-center space-x-8">
        <li>
          <Link
            to="/"
            className="text-lg font-medium hover:text-gray-300 transition-colors"
          >
            Anasayfa
          </Link>
        </li>
        {/* <li>
          <Link
            to="/about"
            className="text-lg font-medium hover:text-gray-300 transition-colors"
          >
            Hakkımda
          </Link>
        </li> */}
        <li>
          <Link
            to="/contact"
            className="text-lg font-medium hover:text-gray-300 transition-colors"
          >
            İletişim
          </Link>
        </li>
        {/* Yorum satırındaki menü öğeleri */}
        {/* 
        <li>
          <Link
            to="/blog"
            className="text-lg font-medium hover:text-gray-300 transition-colors"
          >
            Blog
          </Link>
        </li>
        <li>
          <Link
            to="/adminPanel"
            className="text-lg font-medium hover:text-gray-300 transition-colors"
          >
            AdminPanel
          </Link>
        </li>
        */}
      </ul>
    </nav>
  );
}

export default Navbar;
